import { useRef, useEffect, useState, setState } from "react";
import styles from "./PagePdf.module.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { dadosUsuario } from "services/UtilityFunctions";
import Appbar from "components/AppBar";
import Filter from "components/Filter";
import { useReactToPrint } from "react-to-print";
import Spinner from "components/Spinner";

function PagePdf() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(""); // Estado para armazenar a data selecionada
  
  const queryParams = new URLSearchParams(window.location.search);

const license = queryParams.get('license');
const token = queryParams.get('token');
const username = queryParams.get('username');
const wemp = queryParams.get('wemp');

// Salvar os dados no localStorage ou sessionStorage
localStorage.setItem('license', license);
localStorage.setItem('token', token);
localStorage.setItem('username', username);
localStorage.setItem('wemp', wemp);

  
  
  // Atualizar o estado
  

  useEffect(() => {
    



    if (!token) {
      console.error("Token não encontrado.");
      return;
    }
       // Função que define o timeout de 10 segundos para a requisição
       const TimeOut = (url, options, timeout = 10000) => {
        return Promise.race([
          fetch(url, options),
          new Promise((_, reject) =>
            setTimeout(() => reject(new Error("Não foi possivel acessar os dados")), timeout, setLoading(false))
          
          )
        ]);
      };
    
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    const apiUrl = `https://api.merenda.etibrasil.com.br/api/MerendaPedidos/licenca/${license}`;

    const fetchData = async () => {
      try {
        const response = await TimeOut(apiUrl, { headers });

        
        if (!response.ok) {
          throw new Error('Erro ao buscar dados da API');
        }

        const result = await response.json();
        setData(result);  // Armazena todos os pedidos no estado
        setFilteredData(result); // Inicialmente, mostrar todos os pedidos
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Função para lidar com a mudança da data selecionada
  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setSelectedDate(selectedDate);

    // Filtrar os dados com base na data selecionada
    const filtered = data.filter((pedido) => {
      const pedidoDate = new Date(pedido.datped).toISOString().split('T')[0]; // Pega apenas a parte da data
      return pedidoDate === selectedDate;
    });
    setFilteredData(filtered);
  };

  // Função para limpar o filtro e exibir todos os pedidos novamente
  const handleClearFilter = () => {
    setSelectedDate("");  // Limpa o campo de data
    setFilteredData(data);  // Redefine o estado para mostrar todos os pedidos
  };

  const contentDoc = useRef();

  const handleDownloadPDF = useReactToPrint({
    content: () => contentDoc.current,
  });

//   const handleDownloadPDF = () => {
//     const input = contentDoc.current;
//     html2canvas(input).then((canvas) => {
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF('p', 'mm', 'a4', true);
//       const pdfWidth = pdf.internal.pageSize.getWidth();
//       const pdfHeight = pdf.internal.pageSize.getHeight();
//       const imgWidth = canvas.width;
//       const imgHeight = canvas.height;
//       const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
//       const imgX = (pdfWidth - imgWidth * ratio) / 2;
//       const imgY = 30;
//       pdf.addImage(
//         imgData,
//         'PNG',
//         imgX,
//         imgY,
//         imgWidth * ratio,
//         imgHeight * ratio,
//       );
//       pdf.save('invoice.pdf');
//     });
//   };

  if (loading) 
    return <span>Carregando...</span>;

  if (error) 
    return <span>{error}</span>;

  return (<>
    
    <Appbar
        className = {styles.appbar}
        titulo={"Relatorio de pedidos"}         
    />

    <div className={styles.subtituloContainer}>
        <span className={styles.subtitulo}>Filtre os pedidos e transforme em PDF</span>
    </div>
        
    <div className={styles.filter}>
      
        <Filter
            className={styles.inputFilter}
            type={"date"}
            value={selectedDate}
            onChange={handleDateChange}
            placeholder={"dia/mês/ano"}
        />

        <button className={styles.btnFilter} onClick={handleClearFilter}>Exibir todos</button>    
    </div>
        
    <section className={styles.campoPedidosContainer}>
        <div className={styles.campoPedidosCabecalho}>
            <button  className={styles.pdfButton}
                onClick={handleDownloadPDF}>
                <img
                    className={styles.pdficon} 
                    src={`${process.env.PUBLIC_URL}/icons/pdfIcon.png`}
                    alt="pdf icon"
                />          
                <span className={styles.btnText}>Converter</span>
            </button>
        </div>

        <div className={styles.pedidosTituloContainer}>
            <span className={styles.pedidosTitulo}>
                Pedidos Realizados
            </span>
        </div>

        <div ref={contentDoc} className={styles.pedidosContainer}>
                
            {filteredData.length > 0 ? (
            filteredData.map((pedido) => (
                <div key={pedido.codped} className={styles.pedidosDiv}>
                    <p className={styles.pedidosSubtitulo}>Data da Emissão: {new Date(pedido.datped).toLocaleDateString()}</p>
                    <p className={styles.pedidosSubtitulo}>Código do Pedido: {pedido.codped}</p>
                    <p className={styles.pedidosTexto} >Usuário: {pedido.licenca}</p>
                    <p className={styles.pedidosTexto}>Escola: {pedido.wemp}</p>
                    
                    <p className={styles.pedidosTexto}>Código do Produto: {pedido.codpro}</p>
                    <p className={styles.pedidosTexto}>Quantidade: {pedido.qtdped}</p>
                    
                    <p className={styles.pedidosTexto}>Entrega confirmada: {pedido.confir ? 'Sim' : 'Não'}</p>
                    <p className={styles.pedidosTexto}>Data de Entrega: {pedido.datent ? new Date(pedido.datent).toLocaleDateString() : '' }</p>
                    <hr className={styles.space}/> {/* Separador entre os pedidos */}
                    
                </div>
            ))
            ) : (
            <p>Nenhum pedido encontrado para a data selecionada.</p>
            )}

      </div>

    </section>
 
   </>
  );
}

export default PagePdf;
