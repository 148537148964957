// import hooks
import React, { useState, useEffect, useRef } from 'react';
// import styles
import styles from "./ListOrder.module.css";
// import services
import { pedidosConfirmados, confirmarEntrega, atualizarPedidosConfirmados } from '../../services/PageOrderServices';
import { formatarDataBrasileira, formataString } from 'services/UtilityFunctions';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Link } from 'react-router-dom';

function ListOrder({ groupedPedidos, pedidosAgrupadosPorFamilia, getUsuarioUsername, getProdutoDescricao, getProdutoPerecivel, getProdutoUnimed, getProdutoFator }) {
  // estado para verificar confir
  const [confirmedOrder, setConfirmedOrder] = useState({});

  // Usa useEffect para atualizar a cor dos botões quando `confirmedOrder`(confir) é atualizado
  useEffect(() => {
    atualizarPedidosConfirmados(groupedPedidos, setConfirmedOrder);
  }, [groupedPedidos]);


  

 return (
    <section  className={styles.containerPrincipal}>
      {Object.entries(groupedPedidos).map(([data, pedidosPorUsuario]) => (
        <div key={data}>
         
          <div className={styles.containerData}>
            <div className={styles.data}>
              <img
                className={styles.icon}
                src={`${process.env.PUBLIC_URL}/icons/iconcalendario.png`}
                alt="Ícone"
              />
              {formatarDataBrasileira(data)}
             
              
            </div>
          </div>
          {Object.entries(pedidosPorUsuario).map(([usuario, pedidosDoUsuario]) => {
            const groupedByFamilia = pedidosAgrupadosPorFamilia(pedidosDoUsuario);
            return (
              <div key={usuario}>
                <span className={styles.name}>{getUsuarioUsername(usuario)}</span>
                <div className={styles.containerList}>
                  {Object.entries(groupedByFamilia).map(([familia, pedidosDaFamilia]) => (
                    <React.Fragment key={familia}>
                      <div className={styles.familyTitle}>{formataString(familia)}</div>
                      {pedidosDaFamilia.map((item) => (
                        <React.Fragment key={item.codped}>
                          <hr className={styles.spaces} />
                          <div className={styles.list}>
                            
                            <div className={styles.containerBtn}>
                              {/* Botão para confirmar entrega, com cor dinâmica baseada em codped */}
                              <button
                                className={`${styles.confOrder} ${pedidosConfirmados(item, confirmedOrder)}`}
                                onClick={() => confirmarEntrega(item.codped, setConfirmedOrder, formatarDataBrasileira)}
                              />
                            </div>
  
                            <div className={styles.text}>
                              <span className={styles.nameproduct}>{getProdutoDescricao(item.codpro)}</span>
                              <span className={styles.perishable}>Perecível: {formataString(getProdutoPerecivel(item.codpro))}</span>
                            </div>
                            
                            <div className={styles.values}>
                              <span className={styles.qtd}>Qtd: {item.qtdped}</span>
                              <span className={styles.un}>UN: {getProdutoUnimed(item.codpro)} {formataString(getProdutoFator(item.codpro))}</span>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                      <hr className={styles.spaces} />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </section>
  );
  
}

export default ListOrder;
