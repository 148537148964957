import styles from "./Filter.module.css";

function Filter ({type, value, onChange, placeholder, className}){
    return(
        <input className={className}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    )
}

export default Filter;