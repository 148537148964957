// Import Styles
import styles from "./GroupsButtons.module.css"

// Import Hooks
import React, { useEffect, useState } from "react";

// Import components
import { Link } from 'react-router-dom';


function GroupsButtons({ id, desgru, atualizaCheck, img }) {
  const [temItem, setTemItem] = useState(false); // Estado para alteração do check

  // função para atualizar estado do botao
  useEffect(() => {
    // Recupera o array Alimento
    const storedData = localStorage.getItem('alimentos');

    // Se o array alimento não esta vazio
    if (storedData) {
      const alimentos = JSON.parse(storedData);
      //some() testa se ao menos um dos elementos no array passa na condicional
      // Se ao menos existir um elemneto com Quantidade(qtdpro) maior que zero envia true 
      const TemItemNoPedido = alimentos.some(alimento => alimento.codgru === id && alimento.qtdpro > 0);
      setTemItem(TemItemNoPedido);
    } else {
      // Nenhum alimento no pedido - envia false
      setTemItem(false);
    }
    // Executa a função para cada codgru(id) e a cada atualizaCheck
  }, [id, atualizaCheck]);

  return (
    <Link className={styles.containerButton} to={`/Pedidos/${id}`}>
      {/* Se temItem for True aparece o check, se for false some */}
      {temItem ? (
        <div>
          <img src={`${process.env.PUBLIC_URL}/icons/check.png`} alt={desgru} className={styles.circleVazio} />
        </div>
      ) : (
        <div className={styles.circleVazio}></div>
      )}
      <img src={img} alt={desgru} className={styles.capa} />
      <h2 className={styles.title}>{desgru}</h2>
    </Link>
  );
}

export default GroupsButtons;
