import styles from "./Footer.module.css"

function Footer({subtitulo, texto1, texto2}){
    return(
        <footer className={styles.footer} >
                <div className={styles.containersubtitulo}>
                    <hr className={styles.spaces}/>
                    <div className={styles.subtitulo}>{subtitulo}</div>
                    <hr className={styles.spaces}/>
                </div>
                <div className={styles.textfooter}>
                    <li>{texto1}</li>
                    <li>{texto2}</li>
                </div>
                    
        </footer>
    )
}

export default Footer;