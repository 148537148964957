import styles from "./ReturnButton.module.css"
function ReturnButton ({onClick, src, alt}){

    return(
        <button className={styles.ButtonReturn} onClick={onClick}>
        <img
          src={src}
          alt={alt}
        />
      </button>
    )
}

export default ReturnButton;

