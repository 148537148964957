// Import Styles
import styles from "./PageGroups.module.css";

// Import Components
import GroupsButtons from "components/GroupsButtons";
import Spinner from "components/Spinner";
import ReturnButton from "components/ReturnButton";
import Appbar from "components/AppBar";

//Import Hooks
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Import Services
import { enviarPedido, apagar, card, useGruposPorFamilia } from "services/PageGroupsServices";
import { formataString } from "services/UtilityFunctions";

function PageGroups() {

  // Navegação
  const navigate = useNavigate();

  // States
  const [atualizaCheck, setAtualizaCheck] = useState(false);// Estado para verificar se o check aparece ou não
  const [imagemCard, setImagemCard] = useState(''); // Estado para alterar a imagem do botão dinamicamente

  // Usa o custom hook para obter os estados
    // loading - Estado para exibição do spinner
    // familiaEncontrada - Estado para fazer exibiçao de familia não encontrada
    // grupos - Recebe e salva os Grupos da Api
  const { loading, familiaEncontrada, grupos } = useGruposPorFamilia(atualizaCheck);

  /// Chama a função envia pedido
  const funcaoEnviarPedido = () => {
    enviarPedido(navigate, setAtualizaCheck, atualizaCheck);
  };

  /// Chama a função cancelar pedido
  const apagarPedido = () => {
    apagar(setAtualizaCheck, atualizaCheck);
  };

  // Salva o resultado de Card no estado ImagemCard para ser usado no return
  useEffect(() => {
    const familia = card();
    setImagemCard(familia);
  }, []);


  return (
    <>
      <section>
        {/* Cabeçalho - AppBar*/}
        <Appbar
          titulo={"Alimento"}
          btnVoltar={
            <ReturnButton
              onClick={() => { navigate('/') }}
              src={`${process.env.PUBLIC_URL}/icons/return.png`}
              alt={"ReturnIcon"}
            />
          }
        />

        {/* Botoes Enviar e Cancelar */}
        <div className={styles.buttons}>
          <button className={styles.ButtonApiEnviar} onClick={funcaoEnviarPedido}>
            Enviar
          </button>
          <button className={styles.ButtonApiRemover} onClick={apagarPedido}>
            Cancelar
          </button>
        </div>

        <section className={styles.containerButton}>
          {/* Spinner - enquanto Loading for true exibe o spinner */}
          {loading ?
            (< div className={styles.Spinner}><Spinner /></div>) :
            (<>
              {/* Se encontrar não Familia/Grupo exibe msg de erro*/}
              {familiaEncontrada ?
                (Array.isArray(grupos) && grupos.map((grupo) => (

                  <GroupsButtons
                    id={grupo.codgru}
                    desgru={formataString(grupo.desgru)}
                    key={grupo.codgru}
                    atualizaCheck={atualizaCheck}
                    img={imagemCard}
                  />
                ))
                ) : (
                  <p className={styles.text}>Nenhuma família encontrada.</p>
                )}
            </>)
          }
        </section>
      </section>
    </>
  );
}

export default PageGroups;