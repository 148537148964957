// Import Styles
import styles from "./PageOrder.module.css";

//Import Components
import Spinner from "components/Spinner";
import Filter from "components/Filter";
import Appbar from "components/AppBar";
import ReturnButton from "components/ReturnButton";
import Footer from "components/Footer";
import ListOrder from "components/ListOrder";
import EmptyList from "components/EmptyList";


// Import Hooks
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Import Functions
import {  formatarDataBrasileira, dadosUsuario } from '../../services/UtilityFunctions';
import {useRecuperaDados, pedidosAgrupadosPorFamilia, pedidosAgrupadosPorDataLicenca, filtroData} from "services/PageOrderServices";

function PageOrder() {

  // Navegação entre paginas
  const navigate = useNavigate();

  // Estados
  const [search, setSearch] = useState(""); // String que é fornecida pelo Usuario

  // Recuperar os valores do localStorage
  const { storedWemp, storedLicense } = dadosUsuario();

  /// Effect para fazer os gets da api
  const { pedidos, produtos, grupos, license, loading, listaVazia } = useRecuperaDados(storedWemp, storedLicense);

  

  // recupera a descricao do produto
  const getProdutoDescricao = (codpro) => {
    const produto = produtos.find(produto => produto.codpro === codpro);
    return produto ? produto.despro : 'Produto não encontrado';
  };

  // Recupera o valor de perecivel do produto
  const getProdutoPerecivel = (codpro) => {
    const produtoPerecivel = produtos.find(produtoPerecivel => produtoPerecivel.codpro === codpro);
    return produtoPerecivel ? produtoPerecivel.perecivel : ' Não encontrado';
  };
  // Recupera a unidade de medida do produto
  const getProdutoUnimed = (codpro) => {
    const produtoMedida = produtos.find(produtoMedida => produtoMedida.codpro === codpro);
    return produtoMedida ? produtoMedida.unimed : ' Não encontrado';
  };

  // Recupera o fator de medida do produto
  const getProdutoFator = (codpro) => {
    const produtoFator = produtos.find(produtoFator => produtoFator.codpro === codpro);
    return produtoFator ? produtoFator.fator : ' Não encontrado';
  };

  // Recupera a familia do produto
  const getProdutoFamilia = (codpro) => {
    const produto = produtos.find(produto => produto.codpro === codpro);
    if (produto) {
      const grupo = grupos.find(grupo => grupo.codgru === produto.codgru);
      return grupo ? grupo.familia : 'Família não encontrada';
    }
    return 'Família não encontrada';
  };

  // Recupera o nomusr do Usuario
  const getUsuarioUsername = (licenca) => {
    const licenses = license.find((licenses) => licenses.licenca === licenca);
    return licenses ? licenses.nomusr : "Usuário não encontrado";
  };

  const getPedidosConfir = (codped) => {
    const pedido = pedidos.find((pedido) => pedido.codped === codped);
    return pedido ? pedido.confir : "Confir não encontrado"
  }
  // Agrupa pedido por familia
  const funcaoPedidosAgrupadosPorFamilia = (pedidos) => {
    return pedidosAgrupadosPorFamilia(pedidos, getProdutoFamilia); // `getProdutoFamilia` deve ser a função que você está passando
  };
  // Sala os valores recebidos de pedidosAgrupadosPorDataLicenca em groupedPedidos
  const groupedPedidos = pedidosAgrupadosPorDataLicenca(filtroData(pedidos, search));
  


  // Se o estado lista vazia estiver true - Exibe tela de lista vazia
  if (listaVazia) {
    return (
      <EmptyList />
    );
  }
  // Return Principal
  return (
    <section className={styles.container}>
      {/* AppBar */}
      <Appbar
        titulo={"Encomendas"}
        btnVoltar={
          <ReturnButton
            onClick={() => { navigate('/') }}
            src={`${process.env.PUBLIC_URL}/icons/return.png`}
            alt={"ReturnIcon"}
          ></ReturnButton>
        }
      ></Appbar>
      {/* Spinner */}
      {loading ? (
        <div className={styles.containerList}>
          <div className={styles.Spinner}><Spinner /></div>
        </div>
      ) : (
        <>
          
          <div className={styles.containerComponents}>
            {/* Filtro */}
            <div className={styles.filter}>
              <span className={styles.textfilter}>FILTRO DE DATA</span>
              <Filter
                className={styles.inputFilter}
                type={"date"}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={"dia/mês/ano"}
              />
            </div>
            {/* Listagem dos pedidos ate 30 dias */}
            <div className={styles.containerList}>
              <ListOrder
                // Mapeia os produtos
                groupedPedidos={groupedPedidos}
                // Formata Data para padrao brasileiro
                formatarDataBrasileira={formatarDataBrasileira}
       
                // recupera o nomusr
                getUsuarioUsername={getUsuarioUsername}
                // Agrupa pedidos por familia
                pedidosAgrupadosPorFamilia={funcaoPedidosAgrupadosPorFamilia}
                // recupera o despro
                getProdutoDescricao={getProdutoDescricao}
                // recupera o perecivel
                getProdutoPerecivel={getProdutoPerecivel}
                // recupera o unimed
                getProdutoUnimed={getProdutoUnimed}
                // recupera o fator
                getProdutoFator={getProdutoFator}
              />
            </div>
            {/* Footer */}
            <Footer
              subtitulo={"ETI BRASIL"}
              texto1={"Caso deseje adicionar novos produtos ao pedido, faça um novo pedido para incluí-los."}
              texto2={"Caso queira alterar o pedido, será necessário requisitar para secretaria de Educação."}
            />
          </div>
        </>
      )}

    </section>
  );

}

export default PageOrder;
