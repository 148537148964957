// Import Styles
import styles from "./Cards.module.css";

// Import Hooks
import { Link } from "react-router-dom";


function Cards({url, title, description, imageSrc, text, deliveryDate, deliveryDateBackgroundColor, onClick, backgroundCard}){

    return(
        <div
            style={{background: backgroundCard}}
            className={styles.card}>
            <div className={styles.contaier}>
                <div className={styles.containerIcon}>
                    <img 
                        className={styles.icon}
                        src={ imageSrc}
                        alt={description}
                    />    
                </div>
                <p className={styles.title}>{title}</p>
                {deliveryDate && <p style={{backgroundColor: deliveryDateBackgroundColor}}className={styles.date}>{deliveryDate}</p>}
            </div>
            <div className={styles.containertext}>
                <p className={styles.text}>{text}</p>
                <Link to={url} className={styles.link} onClick={onClick} >Acessar</Link>
            </div>
       </div>
    )
}

export default Cards;