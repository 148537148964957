import styles from "./EmptyList.module.css";
import Appbar from "components/AppBar";
import ReturnButton from "components/ReturnButton";
import Footer from "components/Footer";
import { useNavigate } from "react-router-dom";

function EmptyList(){

    const navigate = useNavigate();

    return(
        <section className={styles.container}>
          <Appbar
              titulo={"Encomendas"}
              btnVoltar={
                  <ReturnButton
                      onClick={() => { navigate('/') }}
                      src={`${process.env.PUBLIC_URL}/icons/return.png`}
                      alt={"ReturnIcon"}
                  ></ReturnButton>
              }
          ></Appbar>
          <div className={styles.containerComponents}>
              <div className={styles.errormsg}>Nenhum pedido encontrado.</div>
              <div className={styles.footer}>
                  <Footer
                      subtitulo={"ETI BRASIL"}
                      texto1={"Caso deseje adicionar novos produtos ao pedido, faça um novo pedido para incluí-los."}
                      texto2={"Caso queira alterar o pedido, será necessário requisitar para secretaria de Educação."}
                  />
              </div>  
          </div>
      </section>
    );
}

export default EmptyList;