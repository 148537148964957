import styles from "./Spinner.module.css"
export default function Spinner(){

    return (
        <>
            <img
                className={styles.Spinner}
                src="\imagens\animatedSpinner.svg"
                alt="Loading Spinner"
             />
        </>
    )
}