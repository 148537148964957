
// Hooks
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Import Pages
// Pagina dos cards
import PageCards from "pages/PageCards";
// Lista de pedidos para entrega
import PageOrder from "pages/PageOrder";
// Pagina da lista dos produtos
import PageFoods from "pages/PageFoods";
// Pagina dos grupos dos produtos
import PageGroups from "pages/PageGroups";
// Pagina dos grupos dos produtos
import PagePdf from "pages/PagePdf";


function AppRoutes(){
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<PageCards/>}></Route> 
                <Route path="/Grupos" element={<PageGroups/>}></Route>
                <Route path="/Historico" element={<PageOrder/>}></Route>
                <Route path="/Pedidos/:id" element={<PageFoods/>}></Route>
                <Route path="/Relatorio" element={<PagePdf/>}></Route>
            </Routes>
            {/*<Rodape/> */}
        </BrowserRouter>
    )
}

export default AppRoutes