
import styles from "./AppBar.module.css";


function Appbar({titulo, btnVoltar}){

    return(
        <div className={styles.appBar}>

            {/* ReturnButton */}
            {btnVoltar} 
            
            <h1 className={styles.titulo}>{titulo}</h1>
            
        </div>  
    )
}

export default Appbar;

