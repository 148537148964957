// Api url
const PATH = 'https://api.merenda.etibrasil.com.br';

// Ednpoinst
  export const PRODUTOS_URL = `${PATH}/api/MerendaProduto`; // Tabela merenda_produto
  export const GRUPOS_URL = `${PATH}/api/MerendaGrupo`; // tabela merenda_grupo
  export const PEDIDOS_URL = `${PATH}/api/MerendaPedidos`; // tabela merenda_pedidos
  export const USUARIO_URL = `${PATH}/api/UsuarioMerenda`; // tabela usuario_merenda
  export const NOME_BANCO_URL = `${PATH}/api/Message`;

