import { PRODUTOS_URL, GRUPOS_URL } from "api";

// Import Hooks
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

// Import Libraries 
import Swal from "sweetalert2";

// Import Functions
import { dadosUsuario } from "./UtilityFunctions";


// Função para fazer o Get todos produtos
export const GetProdutos = async () => {
  try {
    //Recupera o token e verifica se existe
    const { storedToken } = dadosUsuario();
    if (!storedToken) {     
      console.error("Token não encontrado."); 
  }
  // Envia o token para o header 
  const headers = {
      'Authorization': `Bearer ${storedToken}`,
      'Content-Type': 'application/json',
      
  };
    /// Endpoint Get merenda_produto
    const response = await fetch(`${PRODUTOS_URL}`, {headers});
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Erro ao carregar alimentos:', error);
    throw error;
  }
};

/// Função para recuperar o Desgru de merenda_grupo
export const GetNomeGrupos = async (id) => {
  try {
    //Recupera o token e verifica se existe
    const { storedToken } = dadosUsuario();
    if (!storedToken) {     
      console.error("Token não encontrado."); 
  }

  // Envia o token para o header 
  const headers = {
      'Authorization': `Bearer ${storedToken}`,
      'Content-Type': 'application/json',
      
  };
  // Requisição Get Grupos por codgru(id)
    const response = await fetch(`${GRUPOS_URL}/${id}`, {headers});
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();

    // Retorna com o nome do Grupo(desgru)
    return data.desgru;
  } catch (error) {
    console.error('Erro ao carregar descrição do grupo:', error);
    throw error;
  }
};



// Efeito para Exibir os produtos 
export const useFoodEffects = (id, setState) => {
  useEffect(() => {
    // Função para verificar se foi adicionado um produto ao pedido
    const recuperaProdutos = async () => {
      try {
        // Salva os valores do array alimentos em storedData
        const storedData = localStorage.getItem('alimentos');
        if (storedData) {
          // Atualiza o estado com os valores do localstorage convertendo para JSON
          setState(prevState => ({ ...prevState, foods: JSON.parse(storedData) }));
        } else {
          // Se não existir produtos no localstorage, faz requisição na API
          const data = await GetProdutos();
          setState(prevState => ({ ...prevState, foods: data }));
        }
      } catch (error) {
        console.error("Erro ao carregar alimentos:", error);
      }
    };

    // Função para recuperar o desgru
    const recuperaGrupoDescricao = async () => {
      try {
        const nomeGrupos = await GetNomeGrupos(id);
        setState(prevState => ({ ...prevState, groupDescription: nomeGrupos }));
      } catch (error) {
        console.error("Erro ao carregar descrição do grupo:", error);
      }
    };

    // Chama as funções
    recuperaProdutos();
    recuperaGrupoDescricao().finally(() =>
      setState(prevState => ({ ...prevState, loading: false }))
    );
  }, [id, setState]); // dependências necessárias
};

// Função que atualiza a quantidade de um alimento específico
export const atualizarQuantidade = (setState, codpro, novaQuantidade) => {
    setState(prevState => {
      // Busca os produtos adicionados no localStorage verificando o codpro e adiciona a quantidade solicitada (novaQuantidade) no pedido (qtdpro)
      const updateAlimentos = prevState.foods.map(alimento =>
        alimento.codpro === codpro ? { ...alimento, qtdpro: novaQuantidade } : alimento
      );
      // Salva as alterações de quantidade no estado foods
      return { ...prevState, foods: updateAlimentos };
    });
};

// função para salvar alteração de quantidades do pedido
export const useSalvarValores = (state) => {
  const navigate = useNavigate();

  const salvarValores = () => {
    // some() testa se ao menos um dos elementos no array passa no teste implementado pela função atribuída e retorna um valor true ou false.
    // Verifica se algum alimento tem quantidade maior que 0
    const verificaQuantidades = state.foods.some(alimento => alimento.qtdpro > 0);

    if (!verificaQuantidades) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Nenhum item adicionado.",
      });
      return;
    }

    // Salva os valores no localStorage
    localStorage.setItem('alimentos', JSON.stringify(state.foods));

    Swal.fire({
      position: "center",
      icon: "success",
      title: "Itens Adicionados!",
      showConfirmButton: false,
      timer: 1000
    });

    // Navega para a página de grupos
    navigate('/Grupos');
  };

  return salvarValores;
};

// Função limpar pedido
export const limparValores = (state, setState, id) => {
  const limparQuantidade = () => {
    // Verifica se o codgru do alimento é igual ao id da página e define a quantidade para 0
    setState(prevState => {
      const updatedState = prevState.foods.map(alimento =>
        alimento.codgru === parseInt(id) ? { ...alimento, qtdpro: 0 } : alimento
      );
      
      // Salva os produtos com quantidade 0 no localStorage
      localStorage.setItem('alimentos', JSON.stringify(updatedState));
      
      // Atualiza o estado foods com os produtos modificados
      return { ...prevState, foods: updatedState };
    });

    Swal.fire({
      position: "center",
      icon: "success",
      title: "Itens Removidos!",
      showConfirmButton: false,
      timer: 1000
    });
  };

  return limparQuantidade;
};

// Filtro de nomes
export const filtroProdutos = (state, setState, alimentosFiltrados) =>{
  
  const filtroDeProdutosPorNomes = () => {
    //percorre o array alimentosFiltrados, e para cada alimento, verifica se o nome (despro) contém o valor da busca (state.search)
    // Salva a busca em filterFoods
    setState(prevState => ({
      ...prevState,
      filterFoods: alimentosFiltrados.filter(alimento =>
        alimento.despro.toLowerCase().includes(state.search.toLowerCase())
      )
    }));
  };
  return filtroDeProdutosPorNomes;
};

// recebe true caso todos os alimentos tenham qtdpro 0 e false se ao menos um protuto tenha qtdpro > 0.
export const pedidoVazio = (alimentosFiltrados) => {
  // every : retorna um bool para a condicional (qtdpro === 0)
  // Verifica se `alimentosFiltrados` é um array
  if (Array.isArray(alimentosFiltrados)) {
    // Retorna true se todos os alimentos têm `qtdpro` igual a 0
    return alimentosFiltrados.every(alimento => alimento.qtdpro === 0);
  } else {
    // Retorna false se `alimentosFiltrados` não for um array
    console.error("alimentosFiltrados não é um array", alimentosFiltrados);
    return false;
  }
};
