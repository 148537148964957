import styles from "./ProductList.module.css"

function ProductList({ nomeProduto, inputTitulo, inputType, inputMin, inputValue, inputOnchange, perecivel, medida, fator}){
    return(
        <>
            <div className={styles.containerProduto}>

                {/* Exibe a descrição do produto */}
                <span className={styles.nomeProduto}>{nomeProduto}</span> 

                <div className={styles.containerInput}>
                    
                    {/* Titulo do input */}
                    <span className={styles.inputTitulo}>{inputTitulo}</span>

                    {/* input */}
                    <input className={styles.input}  
                        type={inputType}
                        min={inputMin}
                        value={inputValue}
                        onChange={inputOnchange}
                    />

                </div>

            </div>

            <div className={styles.containerDetalhes}>

                {/* Exibide detalhes do produto */}
                <span className={styles.perecivel}>Perecível: {perecivel}</span>
                <span className={styles.unidade}>Medida: {medida} - {fator}</span>
                
            </div>      
        </>
    )
}

export default ProductList;