
// Formata o texto deixando a primeira letra maiuscula e o restante minusculo
export function formataString(str) {
    return str.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
      return a.toUpperCase();
    });
  }


  //Formatar data para o padrão brasileiro 
 export function formatarDataBrasileira(dataStr) {
    const data = new Date(dataStr);
    const dia = String(data.getUTCDate()).padStart(2, '0');
    const mes = String(data.getUTCMonth() + 1).padStart(2, '0');
    const ano = data.getUTCFullYear();
    return `${dia}/${mes}/${ano}`;
  }

// Função para recuperar o token e enviar ao header da requisição
export const recuperaToken = async (url) => {
  const {  storedToken } = dadosUsuario(); 
  
  try {
    const headers = {
      'Authorization': `Bearer ${storedToken}`,
      'Content-Type': 'application/json',
    };
    const response = await fetch(url, { headers });
    if (!response.ok) {
      throw new Error(`Erro ao buscar dados: ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
};
// Recuperar os valores do localStorage 
export const dadosUsuario = () => {
  
  const storedWemp = localStorage.getItem('wemp');
  const storedLicense = localStorage.getItem('license');
  const storedUsername = localStorage.getItem('username');
  const storedToken = localStorage.getItem('token');

  return { storedWemp, storedLicense, storedUsername, storedToken };
};