// Import Styles
import styles from "./ValidateUser.module.css";

// Impoet Hooks
import React, { useEffect, useState } from 'react';

// Import API
import { NOME_BANCO_URL } from '../../api/index';

// Import Services 
import { dadosUsuario } from "services/UtilityFunctions";


const ValidateUser = () => {

  const [state, setState] = useState({
    wemp: '',
    license: '',
    username: '',
    banco: '',
    token: '',
  })

  useEffect(() => {

    // localStorage.setItem('wemp', '0');
    // localStorage.setItem('license', 'aaoafa');
    // localStorage.setItem('username', 'Guilherme');
    // localStorage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYWFvYWZhIiwiZXhwIjoxNzMxNTIzMzYzLCJpc3MiOiJodHRwczovL3d3dy5ldGlicmFzaWwuY29tLmJyLyIsImF1ZCI6Imh0dHBzOi8vd3d3LmV0aWJyYXNpbC5jb20uYnIvIn0.eEVw7OfzasCG6-vermAdtdZO7lKdlLGH1w99nI-_WFM');

    // localStorage.clear();

    // Recuperar os valores do localStorage
    const { storedWemp, storedLicense, storedUsername, storedToken } = dadosUsuario();

    // Atualizar o estado
    setState({
      wemp: storedWemp || '',
      license: storedLicense || '',
      username: storedUsername || '',
      token: storedToken || '',
      banco: '',
    })

    if (!storedToken) {
      console.error("Token não encontrado.");
      setState({
        username: 'Usuário não encontrado',  
      });
    }

    const headers = {
      'Authorization': `Bearer ${storedToken}`,
      'Content-Type': 'application/json',
    };

    // Get nome do banco 
    fetch(NOME_BANCO_URL, { headers })
      .then(response => response.text())
      .then(message => {
        localStorage.setItem('nomeBanco', message);
        setState(prevState => ({ ...prevState, banco: message }));
      })
      .catch(error => {
        console.error('Erro ao buscar a string:', error);
      });

  }, []);

  return (
    <>
      <div className={styles.containerUsername}>
        <span className={styles.username}> {state.username}</span>
        <span className={styles.license}>{state.banco}</span>
      </div>
    </>
  );
};

export default ValidateUser;
