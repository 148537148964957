// Import Styles
import Styles from "./PageCards.module.css";

// Import Components
import ValidateUser from "components/ValidateUser";
import Cards from "components/Cards";


// Pagina dos cards das familias
function PageCards() {

    

  
    return (

        

        <div className={Styles.container}>

            {/* Informações do Usuario */}
            <div className={Styles.containerUsername}>
                <ValidateUser />
            </div>

            {/* Logo */}
            <div className={Styles.containerImg} >
                <img className={Styles.img} src="/imagens/Logo_gpme.jpg"></img>
                <div>
                    <span className={Styles.nameprefeitura}>Secretaria Municipal de Educação</span>
                </div>
            </div>
        
            {/* Cards */}
            <div className={Styles.cardContainer}>

                <Cards
                    onClick={() => {
                        const valorLocalStorage = localStorage.setItem('familia', "estocaveis");
                    }}
                    url={"/Grupos"}
                    title={"Estocaveis"}
                    imageSrc={`${process.env.PUBLIC_URL}/imagens/storable.png`}
                    description={"Ícone Alimentos"}
                    text="Por favor, siga o cardápio da semana disponibilizado pela secretaria ao fazer seu pedido dos alimentos."
                    //deliveryDate={"15"}
                    deliveryDateBackgroundColor="#b0b0b0"
                    backgroundCard="linear-gradient(to top, rgba(0, 114, 150, 0.4) , rgba(255, 250, 250, 0.4))"

                />
                <Cards className={Styles.iconCaixa}
                    onClick={() => {
                        const valorLocalStorage = localStorage.setItem('familia', "hortifruti");
                    }}
                    url={"/Grupos"}
                    title={"Hortifruti"}
                    imageSrc={`${process.env.PUBLIC_URL}/imagens/fruit.png`}
                    description={"Ícone Encomendas"}
                    text="Por favor, siga o cardápio da semana disponibilizado pela secretaria ao fazer seu pedido dos alimentos."
                    //deliveryDate={"7"}
                    deliveryDateBackgroundColor="#6db15b"
                    backgroundCard="linear-gradient(to top, rgb(224, 235, 95, 0.4), rgb(255, 250, 250, 0.4) )"
                />

                <Cards className={Styles.iconCaixa}
                    onClick={() => {
                        const valorLocalStorage = localStorage.setItem('familia', "pereciveis");
                    }}
                    url={"/Grupos"}
                    title={"Perecíveis"}
                    imageSrc={`${process.env.PUBLIC_URL}/imagens/frozen.png`}
                    description={"Ícone Encomendas"}
                    text="Por favor, siga o cardápio da semana disponibilizado pela secretaria ao fazer seu pedido dos alimentos."
                   // deliveryDate={"3"}
                    deliveryDateBackgroundColor="#ff6b46"
                    backgroundCard="linear-gradient(to top, rgb(0, 251, 255, 0.4), rgb(255, 250, 250, 0.4))"
                />

                <Cards className={Styles.iconCaixa}
                    onClick={() => {
                        const valorLocalStorage = localStorage.setItem('familia', "dieta especial");
                    }}
                    url={"/Grupos"}
                    title={"Dieta Especial"}
                    imageSrc={`${process.env.PUBLIC_URL}/imagens/sem-lactose.png`}
                    description={"Ícone Encomendas"}
                    text="Por favor, siga o cardápio da semana disponibilizado pela secretaria ao fazer seu pedido dos alimentos."
                    //deliveryDate={"3"}
                    deliveryDateBackgroundColor="#ff6b46"
                    backgroundCard="linear-gradient(to top, rgb(0, 255, 164, 0.4), rgb(255, 250, 250, 0.4))"
                /> 

                <Cards className={Styles.iconCaixa}
                    url={"/Historico"}
                    title={"Encomendas"}
                    imageSrc={`${process.env.PUBLIC_URL}/imagens/caixote.png`}
                    description={"Ícone Encomendas"}
                    text="Confira o seu pedido e realize as alterações desejadas de acordo com as orientações da secretaria."
                    //backgroundCard="linear-gradient(to right, #000000 30%, #FFFAFA 70%)"
                    backgroundCard="linear-gradient(to top, rgb(0, 0, 0, 0.4), rgb(255, 250, 250, 0.4))"
                />

                
                
            </div>
        </div>
    )
}

export default PageCards;