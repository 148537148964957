import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { recuperaToken, dadosUsuario, formatarDataBrasileira } from './UtilityFunctions'; // Supondo que já tenha essa função utilitária]
// Import API
import { GRUPOS_URL, PRODUTOS_URL, PEDIDOS_URL, USUARIO_URL } from '../api/index';


import styles from "../components/ListOrder/ListOrder.module.css";

// Filtra pedidos ate 30 dias atras
const pedidoDataLimite = (pedidos) => {
    // Recebe a data atual retira 30 dias
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);

    return pedidos.filter(pedido => {
      // recebe a data do pedido enviado
      const pedidoDate = new Date(pedido.datped);
      // retorna pedidos entre hoje e 30 dias atras 
      return pedidoDate >= thirtyDaysAgo && pedidoDate <= today;
    });

  };

  /// Effect para fazer os gets da api
export const useRecuperaDados = (storedWemp, storedLicense) => {
    // Estados
    const [pedidos, setPedidos] = useState([]); // Recebe os Pedidos da api merenda_pedidos
    const [produtos, setProdutos] = useState([]);// Recebe os produtos da api merenda_produto
    const [grupos, setGrupos] = useState([]); // Recebe os grupos da api merenda_grupo
    const [license, setLicense] = useState([]); // Recebe os Usuarios da api usuario_merenda
    const [listaVazia, setListaVazia] = useState(false); // Exibe msg de lista vazia caso não encontre pedidos
    const [loading, setLoading] = useState(true); // Spinner 
    const navigate = useNavigate();
    
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Não foi possível acessar os dados",
          showConfirmButton: false,
          timer: 1500
        });
        console.error("Token não encontrado. Redirecionando para a página inicial.");
        navigate('/');
        return;
      }

      try {
        // Fetch pedidos
        const pedidosData = await recuperaToken(`${PEDIDOS_URL}/wemp/${storedWemp}`, token);
        const dataLimiteFiltrada = pedidoDataLimite(pedidosData);
        setPedidos(dataLimiteFiltrada);
        if (dataLimiteFiltrada.length === 0) {
          setListaVazia(true);
        }

        // Fetch produtos
        const produtosData = await recuperaToken(PRODUTOS_URL, token);
        setProdutos(produtosData);

        // Fetch grupos
        const gruposData = await recuperaToken(GRUPOS_URL, token);
        setGrupos(gruposData);

        // Fetch usuarios
        const usuariosData = await recuperaToken(USUARIO_URL, token);
        setLicense(usuariosData);

      } catch (error) {
        setListaVazia(true);
        console.error("Erro ao carregar dados:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [storedLicense, storedWemp, navigate]);

  return { pedidos, produtos, grupos, license, loading, listaVazia };
};



// Agrupa pedido por familia
export const pedidosAgrupadosPorFamilia = (pedidos, getProdutoFamilia) => {
  // Reduce é um método de arrays que permite reduzir uma lista de elementos a um único valor. 
  // Reduce cria o objeto acc onde as chaves são as famílias dos produtos 
  return pedidos.reduce((acc, pedido) => {
    // obtém a família do produto a partir de seu código (codpro).
    const familia = getProdutoFamilia(pedido.codpro);

    // Verifica se a família já existe no objeto acumulador (acc)
    if (!acc[familia]) {
      // Se não existir cria uma nova chave no objeto com o nome da família e define o valor como um array vazio.
      acc[familia] = [];
    }

    // o pedido é adicionado ao array correspondente a sua família
    acc[familia].push(pedido);
    return acc;
  }, {});
};

// Função que agrupa os pedidos por data e licenca (usuario)
export const pedidosAgrupadosPorDataLicenca = (pedidos) => {
  // Verifica se o estado pedidos é um array válido
  if (!Array.isArray(pedidos)) {
    return {}; // Retorna um objeto vazio se não for um array
  }

  // Reduz os pedidos em um objeto onde as chaves são as datas e os valores são arrays de pedidos agrupados por usuário
  const grouped = pedidos.reduce((acc, pedido) => {
    const dataFormatada = pedido.datped; // Data do pedido formatada
    const usuario = pedido.licenca; // Licenca do usuário

    if (!acc[dataFormatada]) {
      acc[dataFormatada] = {}; // Cria uma nova entrada para a data, se ainda não existir
    }

    if (!acc[dataFormatada][usuario]) {
      acc[dataFormatada][usuario] = []; // Cria um novo array para o usuário, se ainda não existir
    }

    acc[dataFormatada][usuario].push(pedido); // Adiciona o pedido ao array do usuário e data
    return acc;
  }, {});

  // Ordenar as datas em ordem decrescente
  const ordered = Object.keys(grouped)
    .sort((a, b) => new Date(b.split('/').reverse().join('-')) - new Date(a.split('/').reverse().join('-')))
    .reduce((acc, key) => {
      acc[key] = grouped[key];
      return acc;
    }, {});

  return ordered;
};

// Filtro de pedidos (data)
 export const filtroData = (pedidos, search) => {
  //Se search estiver vazio(nenhum valor de busca), a função retorna todos os pedidos sem aplicar nenhum filtro.
  if (!search) return pedidos;
  // Filtra os pedidos pela data enviada pelo Usuario
  return pedidos.filter((item) => {
    const dataHora = item.datped;
    return dataHora.includes(search);
  });
};




// Recupera a descrição do produto

// Função para verificar se o pedido está confirmado pelo codped
// Função para verificar se o pedido está confirmado pelo codped
export const pedidosConfirmados = (pedido, confirmedOrder) => {
  // Verifica se o codped está confirmado no estado
  return confirmedOrder[pedido.codped] ? styles.greenButton : styles.defaultButton;
};


// Função para atualizar os pedidos confirmados com base no codped
export const atualizarPedidosConfirmados = (groupedPedidos, setConfirmedOrder) => {
  Object.values(groupedPedidos).forEach(pedidosPorUsuario => {
    Object.values(pedidosPorUsuario).forEach(pedidosDoUsuario => {
      pedidosDoUsuario.forEach(pedido => {
        if (pedido.confir === true) {
          setConfirmedOrder(prev => ({ ...prev, [pedido.codped]: true }));
        }
      });
    });
  });
};



// Função para confirmar a entrega
export const confirmarEntrega = async (codped, setConfirmedOrder) => {
  const { storedToken, storedWemp } = dadosUsuario();
  const headers = {
    'Authorization': `Bearer ${storedToken}`,
    'Content-Type': 'application/json',
  };
  const body = {
    confir: true,
    datent: new Date().toLocaleDateString('en-CA'), // Data atual no formato 'YYYY-MM-DD'
  };

  // Exibe o modal de confirmação
  return Swal.fire({
    title: "Pedido Recebido?",
    text: "Confirme a entrega do pedido.",
    icon: "question",
    showCancelButton: true,
    cancelButtonText: "Cancelar",
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Confirmar"
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const response = await fetch(`${PEDIDOS_URL}/codped/${codped}`, {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(body),
        });

        if (response.ok) {
          Swal.fire({
            title: "Entrega Confirmada!",
            text: `O pedido com código ${codped} foi entregue na data: ${formatarDataBrasileira(new Date().toLocaleDateString('en-CA'))}.`,
            icon: "success"
          });
          // Atualiza o estado para que o botão mude de cor automaticamente
          setConfirmedOrder(prev => ({ ...prev, [codped]: true }));
        } else {
          Swal.fire({
            title: "Erro!",
            text: "Erro ao confirmar a entrega.",
            icon: "error"
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Erro!",
          text: `Ocorreu um erro: ${error.message}`,
          icon: "error"
        });
      }
    }
  });
};
